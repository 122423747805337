<template>
  <div class="page-box">
    <base-screen>
      <a-form-model ref="formRef" layout="inline" :model="queryRef" @submit="handleSearch" @submit.native.prevent>
        <a-form-model-item label="商品名称" prop="name">
          <a-input v-model="queryRef.name" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="最低零售价" prop="lowMinPrice" style="margin-right: 0">
          <div class="custom-link-box">
            <a-input v-model.number="queryRef.lowMinPrice" placeholder="请输入" prefix="￥" @input="inputFormat('lowMinPrice', arguments[0])" />
            <span class="connector">-</span>
            <a-form-model-item prop="highMinPrice">
              <a-input v-model.number="queryRef.highMinPrice" placeholder="请输入" prefix="￥" @input="inputFormat('highMinPrice', arguments[0])" />
            </a-form-model-item>
          </div>
        </a-form-model-item>
        <a-form-model-item label="最高零售价" prop="lowMaxPrice">
          <div class="custom-link-box">
            <a-input v-model="queryRef.lowMaxPrice" placeholder="请输入" suffix="%" />
            <span class="connector">-</span>
            <a-form-model-item prop="highMaxPrice">
              <a-input v-model="queryRef.highMaxPrice" placeholder="请输入" suffix="%" />
            </a-form-model-item>
          </div>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="handleSearch">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </base-screen>

    <base-tabs :tabs="stateList" :active="queryRef.sortBy" @change="handleStateChange" />

    <a-spin :spinning="loadingRef">
      <div class="custom-goods-list">
        <div v-if="dataRef.length" class="list-box fix">
          <div class="goods-item-box" v-for="item in dataRef" :key="item.productId">
            <div class="goods-item">
              <div class="item-info-box" >
                <div class="goods-img rect-100">
                  <base-img class="full" :src="item.picPath" width="150" height="150" oss_style="150_150" />
                </div>
                <p class="goods-name el2">{{item.name}}</p>
                <div class="goods-info">
                  <p>零售价：¥{{item.minPrice}}<span v-if="item.minPrice !== item.maxPrice">~¥{{item.maxPrice}}</span></p>
                </div>
                <p>批发价：¥{{item.minStepPrice}}<span v-if="item.minStepPrice !== item.maxStepPrice">~¥{{item.maxStepPrice}}</span></p>
              </div>
              <a href="javascript:;" class="shop-name">
                <div class='shop-name-name el' @click="$router.push(`/goods/shop_goods_list/${item.shopId}`)">{{item.shopName}}</div>
                <a-button style="font-size: 12px; padding-right: 0;" type="link" size="small" @click="handleCollect(item)">去进货</a-button>
              </a>
            </div>
          </div>
        </div>
        <div v-else style="padding: 12px 0">
          <a-empty />
        </div>
      </div>
      <div class="custom-pagination">
        <a-pagination v-model="pageRtv.current" size="small" :page-size="pageRtv.size" :total="pageRtv.total" :show-total="total => `共 ${total} 条`" />
      </div>
    </a-spin>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, reactive, toRefs, watch } from '@vue/composition-api'
import { common, goods } from '@/api'
import { useTableList } from '@/hooks'
import { SHOPID } from '@/constants'
import { getSession } from '@/utils/session'

const stateList = [
  { title: '综合', value: 1 },
  { title: '销量', value: 2 },
  { title: '批发价', value: 3 },
  { title: '上架时间', value: 4 },
]
export default {
  name: 'PageBatchMarket',

  setup (props, { root }) {
    const formRef = ref(null)
    const queryRef = ref({
      name: '',
      sortBy: 0,
      lowMinPrice: '',
      highMinPrice: '',
      lowMaxPrice: '',
      highMaxPrice: '',
    })
    const state = reactive({
      currentShopId: getSession(SHOPID),
      getGroupListLoading: false,
      productGroupList: [],
    })

    const { dataRef, pageRtv, loadingRef, resetPage, setPage, refresh } = useTableList(
      goods.getBatchPage,
      queryRef,
      true,
      (res) => res || []
    )

    // 订阅源：发布商品
    onMounted(() => {
      root.$bus.$on('CollectDetail:refresh', refresh)
    })
    onUnmounted(() => {
      root.$bus.$off('CollectDetail:refresh', refresh)
    })

    function handleSearch () {
      resetPage()
      refresh()
    }

    function handleReset () {
      formRef.value.resetFields()
      handleSearch()
    }

    function handleTableChange ({ current }) {
      setPage(current)
    }

    function handleStateChange (state) {
      queryRef.value.sortBy = state
      handleSearch()
    }

    function inputFormat (variable, e) {
      queryRef.value[variable] = e.target.value.replace(/[^0-9]/gi, '')
    }

    function handleCollect ({ productId }) {
      root.$router.push(`/purchase/goods_batch_detail/${productId}`)
    }
    return {
      ...toRefs(state),
      stateList,
      formRef,
      queryRef,
      dataRef,
      pageRtv,
      loadingRef,
      handleSearch,
      handleReset,
      handleTableChange,
      handleStateChange,
      inputFormat,
      handleCollect,
    }
  },
}
</script>

<style lang="less" scoped>
.custom-spin {
  width: 170px;
  ::v-deep > div > .ant-spin .ant-spin-dot {
    left: initial;
    right: 18px;
  }
}
.custom-link-box {
  display: flex;
  align-items: center;
  .connector {
    margin: 0 3px;
  }
  ::v-deep .ant-input-affix-wrapper {
    width: 96px;
  }
}
.custom-pagination {
  text-align: right;
}
.shop-name-name {
  max-width: calc(100% - 24px);
}
.shop-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-row {
  display: flex;
  align-items: center;
}
.modal-goods-list {
  max-height:500px;
  overflow: auto;
  .modal-goods-list-box {
    display: flex;
    flex-wrap: wrap;
    .modal-goods-item-box {
      width: calc(100% / 3);
      flex-shrink: 0;
      padding: 0 8px;
      box-sizing: border-box;
      margin-bottom: 12px;
      .modal-goods-item {
        display: flex;
        background: #eee;
        border-radius: 6px;
        padding: 12px;
        height:104px;
        .modal-goods-name {
          font-size: 16px;
          font-weight: 900;
          color:#000;
        }
        .modal-goods-shop-name {
          font-size: 14px;
          margin-top: 6px;
        }
        .delete-btn {
          padding: 0;
          height: 28px;
          line-height: 28px;
          color: red;
          margin-top:12px;
          margin-right:-4px;
        }
        .modal-goods-img {
          margin-right: 12px;
          flex-shrink: 0;
          width: 80px;
          height: 80px;
          ::v-deep .img-default {
            width: 80px!important;
            height: 80px!important;
          }
          ::v-deep img {
            width: 80px;
            height: 80px;
          }
        }
      }
    }
  }
}
</style>
